import { useEffect, useRef, useState } from "react";

import { Link } from "react-router-dom";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useMobileDetector } from "../../../../utils/customHooks";

import {
	PopupBody,
	PopupWrapper,
	CloseBtn,
	PopupContainer,
	PopupHeading,
	TextRow,
	PopupOverview,
	OverviewDescriptionContainer,
} from "./styles";

import Services from "./Services";
import Outcomes from "./Outcomes";
import Pictures from "./Pictures";
// import Results from "./Results";

import popupArrow from "../../../../Assets/Images/Home/footer-arrow.svg";

import projects from "../../../../Assets/Lang/en/work/projects.json";
import { PopupAbout } from "./About/styles";
import { PopupResults } from "./Results/styles";

gsap.registerPlugin(ScrollTrigger);

const ProjectsPopup = ({ handleClose, info }) => {
	const ismobile = useMobileDetector();
	const textOverviewRef = useRef(null);
	const triggerOverviewRef = useRef(null);
	const textAboutRef = useRef(null);
	const triggerAboutRef = useRef(null);
	const textResultsRef = useRef(null);
	const triggerResultsRef = useRef(null);
	const scrollerRef = useRef(null);
	const sectionRef = useRef(null);

	const [showMore, setShowMore] = useState(false);
	const [initialDescription, setInitialDescription] = useState("");
	const [remainingDescription, setRemainingDescription] = useState("");
	const [isOpen, setIsOpen] = useState(false);

	const currentProject = projects[info.project].inner;

	const textOverview = currentProject.overview.title;
	const textAbout = currentProject.about;
	const textResults = currentProject.results;
	const descriptionParagraphs = currentProject.overview.description;

	useEffect(() => {
		if (descriptionParagraphs) {
			const paragraphs = descriptionParagraphs.split("<br />");
			const initial = paragraphs[0];
			const remaining = paragraphs.slice(1).join("<br />");
			setInitialDescription(initial);
			setRemainingDescription(remaining);
		}
	}, [descriptionParagraphs]);

	const toggleShowMore = () => {
		setShowMore(!showMore);
	};

	const openPopup = () => {
		setIsOpen(true);
		return true;
	};

	const closePopup = () => {
		document.body.style.background = "none";
		document.body.style.backdropFilter = "none";

		setIsOpen(false);
		handleClose();
	};

	useEffect(() => {
		if (openPopup()) {
			sectionRef.current.style.background = "rgba(35, 35, 35, 0.5)";
			sectionRef.current.style.backdropFilter = "blur(10px)";
		}

		// disabling scrolling on body doesn't work!
		document.body.style.overflow = "hidden"; // disable scrolling on the page
		document.documentElement.style.overflow = "hidden"; // disable scrolling on the page
		const enableScroll = () => {
			document.documentElement.style.overflow = "auto";
			document.body.style.overflow = "auto";
		};

		textOverviewRef.current.innerHTML = textOverview
			.split("")
			.map((char) => `<span>${char}</span>`)
			.join("");
		textAboutRef.current.innerHTML = textAbout
			.split("")
			.map((char) => `<span>${char}</span>`)
			.join("");
		textResultsRef.current.innerHTML = textResults
			.split("")
			.map((char) => `<span>${char}</span>`)
			.join("");

		const lettersOverview =
			textOverviewRef.current.querySelectorAll("span");
		const lettersAbout = textAboutRef.current.querySelectorAll("span");
		const lettersResults = textResultsRef.current.querySelectorAll("span");

		gsap.set(lettersOverview, {
			color: "#e0e0e0",
		});
		gsap.set(lettersAbout, {
			color: "#e0e0e0",
		});
		gsap.set(lettersResults, {
			color: "#e0e0e0",
		});

		const animationOverview = gsap.to(lettersOverview, {
			scrollTrigger: {
				trigger: triggerOverviewRef.current,
				start: "top center",
				end: "bottom 50",
				scroller: scrollerRef.current,
				scrub: true,
				pin: false,
				// resolves the bug with the rest of letter by letter animations
				onEnter: () => ScrollTrigger.refresh(),
				onLeave: () => ScrollTrigger.refresh(),
			},
			color: "#000000",
			stagger: 0.1, // Adjusted stagger for smoother transition
			duration: 1,
		});
		const animationAbout = gsap.to(lettersAbout, {
			scrollTrigger: {
				trigger: triggerAboutRef.current,
				start: "top center",
				end: "bottom center",
				scroller: scrollerRef.current,
				scrub: true,
				pin: false,
				// resolves the bug with the rest of letter by letter animations
				onEnter: () => ScrollTrigger.refresh(),
				onLeave: () => ScrollTrigger.refresh(),
			},
			color: "#000000",
			stagger: 0.1,
			duration: 1,
		});
		const animationResults = gsap.to(lettersResults, {
			scrollTrigger: {
				trigger: triggerResultsRef.current,
				start: "top 70%",
				end: "bottom 90%",
				scroller: scrollerRef.current,
				scrub: true,
				pin: false,
				// resolves the bug with the rest of letter by letter animations
				onEnter: () => ScrollTrigger.refresh(),
				onLeave: () => ScrollTrigger.refresh(),
			},
			color: "#000000",
			stagger: 0.1,
			duration: 1,
		});

		return () => {
			if (animationOverview.scrollTrigger) {
				animationOverview.scrollTrigger.kill();
			}
			animationOverview.kill();

			if (animationAbout.scrollTrigger) {
				animationAbout.scrollTrigger.kill();
			}
			animationAbout.kill();

			if (animationResults.scrollTrigger) {
				animationResults.scrollTrigger.kill();
			}
			animationResults.kill();

			enableScroll(); // re-enable scrolling on the page
		};
	}, []);

	return (
		<PopupWrapper onClick={handleClose} ref={sectionRef} isOpen={isOpen}>
			<PopupBody onClick={(e) => e.stopPropagation()} isOpen={isOpen}>
				<CloseBtn onClick={closePopup}>
					<div />
				</CloseBtn>
				<PopupContainer ref={scrollerRef}>
					<PopupHeading>
						<TextRow>
							{/*{!ismobile && <span>&emsp;&nbsp;&nbsp;&nbsp;</span>}*/}
							<span
								dangerouslySetInnerHTML={{
									__html: currentProject.heading,
								}}
							></span>
						</TextRow>
					</PopupHeading>
					<Services servicesObj={currentProject.services} />
					<PopupOverview ismobile={ismobile ? 1 : 0}>
						<div>
							<span>Overview</span>
						</div>
						<div ref={triggerOverviewRef}>
							{!ismobile && (
								<span>
									&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&nbsp;
								</span>
							)}
							<span ref={textOverviewRef}></span>
						</div>
						<OverviewDescriptionContainer>
							<div>
								{ismobile ? (
									<p>
										<span
											dangerouslySetInnerHTML={{
												__html:
													initialDescription +
													(showMore
														? "<br />" +
															remainingDescription
														: ""),
											}}
										></span>
										{!showMore && remainingDescription && (
											<span onClick={toggleShowMore}>
												read more
											</span>
										)}
										{showMore && (
											<span onClick={toggleShowMore}>
												{" "}
												read less
											</span>
										)}
									</p>
								) : (
									<>
										{descriptionParagraphs
											.split("<br />")
											.map((paragraph, index) => (
												<p
													key={index}
													dangerouslySetInnerHTML={{
														__html: paragraph,
													}}
												></p>
											))}
									</>
								)}
							</div>
							<Link to="/contact-us">
								Order project <img src={popupArrow} alt="" />
							</Link>
						</OverviewDescriptionContainer>
					</PopupOverview>
					<Outcomes outcomesObj={currentProject.outcomes} />
					<Pictures picturesObj={currentProject.images1} />
					<PopupAbout>
						<div>
							<span>About</span>
						</div>
						<div ref={triggerAboutRef}>
							{!ismobile && (
								<span>
									&emsp;&emsp;&emsp;&emsp;&emsp;&nbsp;
								</span>
							)}
							<span ref={textAboutRef}></span>
						</div>
					</PopupAbout>
					<Pictures picturesObj={currentProject.images2} />
					<PopupResults>
						<div>
							<span>Results</span>
						</div>

						<div ref={triggerResultsRef}>
							<span>
								{!ismobile && (
									<span>
										&emsp;&emsp;&emsp;&emsp;&emsp;&nbsp;
									</span>
								)}
								<span ref={textResultsRef}></span>
							</span>

							<div>
								<Link to="/contact-us">
									Let’s talk and discuss your project{" "}
									<img src={popupArrow} alt="" />
								</Link>
							</div>
						</div>
					</PopupResults>
					{/*<Results*/}
					{/*	resultsText={currentProject.results}*/}
					{/*	popupArrow={popupArrow}*/}
					{/*	scrollerRef={scrollerRef}*/}
					{/*/>*/}
				</PopupContainer>
			</PopupBody>
		</PopupWrapper>
	);
};

export default ProjectsPopup;
